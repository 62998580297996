<template>
  <div></div>
</template>

<script>
import {} from "bootstrap-vue"

export default {
  components: {},
  data() {
    return {
      contentWidth: this.$store.state.appConfig.layout.contentWidth
    }
  },
  created() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "boxed")
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", this.contentWidth)
  }
}
</script>
